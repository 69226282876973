import React, { useContext, useEffect, useState, useMemo } from "react";
import background1 from "../../scss/imagenes/VA045-min.webp";
import background2 from "../../scss/imagenes/VA013-min2.webp";
import background3 from "../../scss/imagenes/VA057-min2.webp";
import background4 from "../../scss/imagenes/VA049-min2.webp";

import { GlobalState } from "../../GlobalState";
import { useMediaQuery } from "react-responsive";

const BanerParalax = () => {
    const isPhone = useMediaQuery({ query: "(min-width: 428px)" });

    const botonesElement = useMemo(() => {
        return [
            {
                id: "inicio",
                color: "rgba( 226, 92, 104, 0.8)",
                titulo: " NIDEL ",
                frase: "¡Te invitamos a conocernos y ser parte de este sueño!",
                image: background1,
            },
            {
                id: "educacion",
                color: "rgba( 97, 80, 160, 0.8)",
                titulo: "Educación",
                frase: "Leer, escribir y resolver operaciones matemáticas son las principales necesidades de las familias que buscan apoyo en la fundación. \n Para nosotros estas son habilidades humanas que integradas a la conexión con su propio ser, los otros seres humanos y otros seres, se convierten en instrumentos que permiten a los niños y niñas repensar el mundo, reorganizar el propio pensamiento y promover acciones de cambio.",
                image: background2,
            },
            {
                id: "material",
                color: "rgba( 237, 176, 64, 0.8)",
                titulo: "Juegos como metodología",
                frase: "El juego es la manera innata de aprender, es por esto que, utilizamos esta herramienta para retornar a la alegría de aprender. \n Desarrollamos varios juegos y materiales pedagógicos que aportan al proceso de enseñanza – aprendizaje para difundirlos y ponerlos a la venta.",
                image: background3,
            },
            {
                id: "apoyo",
                color: "rgba( 65, 142, 82, 0.8)",
                titulo: "Apóyanos",
                frase: `Se parte de este sueño a través de: 
                \nDonaciones
                Voluntariados 
                Construcción de redes de apoyo
                Compra de juegos y material pedagógico
                `,
                image: background4,
            },
        ];
    }, []);

    const { circleActive } = useContext(GlobalState);
    const [datosBotones, setDatosBotones] = useState(botonesElement[0]);

    useEffect(() => {
        const element =
            botonesElement.find((e) => e.id === circleActive) || botonesElement[0];
        if (isPhone) {
            setDatosBotones(element);
        } else {
            setDatosBotones(botonesElement[0]);
        }
    }, [circleActive, isPhone, botonesElement]);

    if (isPhone) {
        return (
            <div
                className="bannerParalax"
                style={{ backgroundImage: `url(${datosBotones.image})` }}
            >
                <div
                    className="contentParalax"
                    style={{
                        backgroundImage: `linear-gradient(to bottom, rgba(255, 0, 0, 0), ${datosBotones.color})`,
                    }}
                >
                    <h2 className="tituloBanner">{datosBotones.titulo}</h2>
                    <h4 className="fraseBanner">{datosBotones.frase}</h4>
                </div>
            </div>
        );
    } else {
        return (
            <>
                {botonesElement.map((boton) => (
                    <div className="bannerParalax"
                        style={{ backgroundImage: `url(${boton.image})` }}>
                        <div key={boton.id} className="contentParalax" style={{
                            backgroundImage: `linear-gradient(to bottom, rgba(255, 0, 0, 0), ${boton.color})`,
                        }}>
                            <h2 className="tituloBanner">{boton.titulo}</h2>
                            <h4 className="fraseBanner">{boton.frase}</h4>
                        </div>
                    </div>
                ))
                }
            </>
        )
    }

};

export default BanerParalax;
