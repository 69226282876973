import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import centro from '../../scss/imagenes/casa-corazon.webp'
import juegos from '../../scss/imagenes/juegos1.webp'
import apoyo from '../../scss/imagenes/manos-apoyo.webp'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { GlobalState } from '../../GlobalState';

const ProyectosBotones = () => {

    const { setCircleActive } = useContext(GlobalState);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".botones",
                start: 'top 80%', //opcional
                end: 'bottom 500px',
                // markers: true,
                toggleActions: 'restart none none reverse',
                // toggleActions: 'restart complete none reverse',
            }
        });
        tl.fromTo('.linkCircleButton',
            {
                x: '-60',
                opacity: 0,
                ease: 'ease-in',
            },
            {
                x: '0',
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2
            }, 0.2
        );
    }, [])

    return (
        <div className='botones'>
            <Link to="/educacion" className='linkCircleButton'>
                <div className='circleButton voluntarios' onMouseEnter={() => setCircleActive("educacion")}>
                    <img src={centro} alt="Voluntarios" />
                </div>
                <h2>Centros de apoyo <br /> escolar integral</h2>
            </Link>

            <Link to="/materiales" className='linkCircleButton'>
                <div className='circleButton proyectos' onMouseEnter={() => setCircleActive("material")}>
                    <img src={juegos} alt="Juegos" className='juegosImagen' />
                </div>
                <h2>Juegos y materiales <br /> pedagógico</h2>
            </Link>

            <Link to="/unete" className='linkCircleButton'>
                <div className='circleButton juegos' onMouseEnter={() => setCircleActive("apoyo")}>
                    <img src={apoyo} alt="Proyectos" />
                </div>
                <h2> Apóyanos</h2>
            </Link>
        </div>
    )
}

export default ProyectosBotones