import React from 'react'
import Slider from "react-slick";

import { imagesBanner } from './sliderImages'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "", left: "97%" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "", left: "2%", zIndex: "9999" }}
            onClick={onClick}
        />
    );
}

export default function Hero() {

    const settings = {
        // dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    return (
        <div className='hero'>
            <Slider {...settings} className='slider'>
                {imagesBanner.map((image, key) => (
                    <div key={key}>
                        <img src={image.image} alt={image.alt} />
                    </div>
                ))
                }

            </Slider>
        </div>
    )
}
