import React, { useEffect } from "react";
import {
    FaFacebook,
    FaInstagram,
    FaWhatsapp,
    FaMailBulk,
} from "react-icons/fa";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import ReactWhatsapp from "react-whatsapp";

const whatsappNumber = "+59168166445";
const whatsappMessage = "Hola Nidel...";

const facebookLink = "https://fb.me/fundacionNidel";

const AyudanosHome = () => {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".AyudanosHome",
                start: "top 80%", //opcional
                end: "bottom 500px",
                toggleActions: "restart none none reverse",
            },
        });
        tl.fromTo(
            ".fb",
            {
                y: "-200",
                opacity: 0,
            },
            {
                y: "0",
                opacity: 1,
                ease: "Elastic.easeOut",
                duration: 3,
            },
            0.2
        );
        tl.fromTo(
            ".in",
            {
                y: "-200",
                opacity: 0,
            },
            {
                y: "-50",
                opacity: 1,
                ease: "Elastic.easeOut",
                duration: 3,
            },
            0.2
        );
        tl.fromTo(
            ".wh",
            {
                y: "-200",
                opacity: 0,
            },
            {
                y: "0",
                opacity: 1,
                ease: "Elastic.easeOut",
                duration: 3,
            },
            0.2
        );
        tl.fromTo(
            ".ml",
            {
                y: "-200",
                opacity: 0,
            },
            {
                y: "-50",
                opacity: 1,
                ease: "Elastic.easeOut",
                duration: 3,
            },
            0.2
        );

        tl.fromTo(
            ".text",
            {
                x: "-200",
                opacity: 0,
            },
            {
                x: "0",
                opacity: 1,
                ease: "easeOut",
                duration: 2,
            },
            0.2
        );

        tl.fromTo(
            ".apoyo",
            {
                x: "-200",
                opacity: 0,
            },
            {
                x: "0",
                opacity: 1,
                ease: "easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    return (
        <div className="AyudanosHome">
            <div className="lluviaIconos">
                <a href={facebookLink} target="_blank" rel="noreferrer noopener">
                    <FaFacebook className="icon fb" />
                </a>
                <FaInstagram className="icon in" />
                <ReactWhatsapp
                    number={whatsappNumber}
                    message={whatsappMessage}
                    style={{ backgroundColor: "transparent", border: "none" }}
                >
                    <FaWhatsapp className="icon wh" />
                </ReactWhatsapp>
                <FaMailBulk className="icon ml" />
            </div>

            <h3 className="text">Síguenos y comparte nuestra misión</h3>
            <h2 className="apoyo">
            </h2>
        </div>
    );
};

export default AyudanosHome;
