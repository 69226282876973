import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import banner from "../scss/imagenes/VA032-min.webp";
import niveles from "../scss/imagenes/Niveles.webp"
import cicloImg from "../scss/imagenes/ciclo.webp";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";

const Educacion = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".intro",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "restart none none reverse",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl.fromTo(
            ".intro",
            {
                y: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    useEffect(() => {
        let tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".imgContent",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "start complete none none",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl2.fromTo(
            ".imgContent",
            {
                y: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    return (
        <div className="fullContent">
            <Helmet>
                <title>Centros de apoyo integral Nidel</title>
                <meta name='description' content='Centros de apoyo Nidel' />
                <meta name='keywords' content='educación, nidel, centros, apoyo' />
            </Helmet>
            <div className="banner">
                <img src={banner} alt="educacion-banner" />
            </div>
            <div className="content">
                <div className="intro">
                    <h2>Centros de apoyo integral</h2>
                    <p>
                        En los centros generamos un espacio de re-conexión dando un servicio
                        de apoyo educativo integral para niños y niñas, utilizando
                        metodologías lúdicas para fortalecer el desarrollo pleno de su
                        potencial y motivar la autogestión de su aprendizaje, para ser
                        miembros de la comunidad que cuidan de su propio ser, de su
                        comunidad y del planeta.
                    </p>
                </div>
                <div className="imgContent" style={{ marginTop: "80px" }}>
                    <img src={cicloImg} alt="Ciclo" />
                </div>
                <div className="textoFinal">
                    <div className="texto">
                        <p>Nuestros Centros de apoyo educativo integral se encuentran en las ciudades de
                            <b> Potosí  y El Alto.</b>
                        </p>
                    </div>
                    <div className="imgContent">
                        <img className="" src={niveles} alt="niveles" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Educacion;
