import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Contact from "./Contact";
import banner from "../scss/imagenes/VA045-min.webp";
import donacionesIcon from '../scss/imagenes/donacionesIcon.webp'
import voluntariadoIcon from '../scss/imagenes/voluntariadoIcon.webp'
import materialIcon from '../scss/imagenes/materialIcon.webp'
import redesIcon from '../scss/imagenes/redes.webp'

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Mapas } from "./Mapas";
import { Helmet } from "react-helmet";

const Unete = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".intro",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "restart none none reverse",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl.fromTo(
            ".intro",
            {
                y: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    useEffect(() => {
        let tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".textos",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "restart none none reverse",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl2.fromTo(
            ".textos",
            {
                y: "60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    useEffect(() => {
        let tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".contactanos",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "restart complete none none",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl3.fromTo(
            ".contactanos",
            {
                y: "60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    return (
        <div className="fullContent">
            <Helmet>
                <title>Únete Nidel</title>
                <meta name='description' content='Únete' />
                <meta name='keywords' content='donar, apoyar, contactar' />
            </Helmet>
            <div className="banner">
                <img src={banner} alt="conocenos-banner" />
            </div>
            <div className="content">
                <div className="intro">
                    <h2>Únete y colabora</h2>
                    <h3>Se parte de este sueño de transformación educativa.</h3>
                </div>

                <div className="textos">
                    <div className="barrasColores donaciones">
                        <img src={donacionesIcon} alt="donaciones" />
                        <div className="text">
                            <h2>Donaciones</h2>
                            <br />
                            <p>
                                <b>
                                    Todos tus aportes nos permiten continuar con esta labor para
                                    llegar a beneficiar a más niños y niñas con el desarrollo de sus
                                    habilidades. <br /> ¡Contáctate con nosotros!
                                </b>
                            </p>
                        </div>
                    </div>
                    <div className="barrasColores voluntariado">
                        <img src={voluntariadoIcon} alt="valuntariado" />
                        <div className="text">
                            <h2>Voluntariado</h2>
                            <br />
                            <p>
                                <b>
                                    La Fundación necesita voluntarios, para funcionar y evolucionar, que compartan su tiempo, conocimientos, amor, esfuerzo, habilidades, talentos, vocación, etc. para contribuir en la construcción de un aprendizaje significativo.
                                    <br />¡Si te gustaría ser parte de la familia NIDEL contáctate con nosotros!

                                </b>
                            </p>
                        </div>
                    </div>
                    <div className="barrasColores material">
                        <img src={materialIcon} alt="material" />
                        <div className="text">
                            <h2>Compra de juegos y material pedagógico</h2>
                            <br />
                            <p>
                                <b>
                                    Cuando tú compras los juegos y materiales elaborados en la fundación, nosotros obtenemos recursos para continuar fortaleciendo las habilidades de nuestras niñas y niños, acompañándolos en su crecimiento mediante una atención integral.
                                    <br />¡Contáctate con nosotros!
                                </b>
                            </p>
                        </div>
                    </div>
                    <div className="barrasColores redes">
                        <img src={redesIcon} alt="redes" />
                        <div className="text">
                            <h2>Construcción de redes de apoyo</h2>
                            <br />
                            <p>
                                <b>
                                    Como fundación creemos que la conexión con otras entidades, fundaciones, organizaciones y personas, enriquecen el servicio que brindamos.
                                    <br />Contáctate con nosotros si te gustaría trabajar de manera colaborativa.

                                </b>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="contactanos">
                    <h1>Contactanos</h1>
                    <div className="elementosFila">

                        <Contact className="contacto" />
                        <Mapas />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Unete;
