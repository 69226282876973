import React, { useState } from "react";

//Lenguajes
import { IntlProvider } from 'react-intl'

import InglesJson from './Lang/en-US.json'
import EspJson from './Lang/es-BO.json'
import FrancesJson from './Lang/fr-FR.json'

const GlobalState = React.createContext()
const DataProvider = ({ children }) => {

    const [mensajes, setMensajes] = useState(EspJson);
    const [locale, setLocale] = useState('en-US')

    const [circleActive, setCircleActive] = useState("")

    const establecerLenguaje = (lenguaje) => {
        switch (lenguaje) {
            case 'es-BO':
                setLocale('es-BO')
                setMensajes(EspJson)
                break;
            case 'en-US':
                setLocale('en-US')
                setMensajes(InglesJson)
                break;
            case 'fr-FR':
                setLocale('fr-FR')
                setMensajes(FrancesJson)
                break;
            default:
                setLocale('en-US')
                setMensajes(InglesJson)
        }
    }

    const globalState = {
        establecerLenguaje,
        circleActive,
        setCircleActive
    }
    return (
        <GlobalState.Provider value={globalState}>
            <IntlProvider locale={locale} messages={mensajes}>
                {children}
            </IntlProvider>
        </GlobalState.Provider>
    )
}

export { DataProvider, GlobalState }