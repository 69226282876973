export const Mapas = () => {
    return (
        <div className='mapas'>
            <h2>Nos puedes encontrar en:</h2>

            <div className='rowMapa'>

                <div className='mapa'>
                    <p>
                        Ciudad de El Alto <br />
                        Zona Alto Lima Segunda Sección, <br />Calle Sorata N°2000.
                    </p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3825.8960582958703!2d-68.1813886851361!3d-16.480799988627805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb0d38ac4f2d77751!2zMTbCsDI4JzUwLjkiUyA2OMKwMTAnNDUuMSJX!5e0!3m2!1ses!2sbo!4v1666498232966!5m2!1ses!2sbo"
                        width="300"
                        title="direccion1"
                        height="300"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0" />
                </div>

                <div className='mapa'>
                    <p>
                        Ciudad de Potosí <br />
                        Zona San Pedro, <br />Calle Rojas de Luna Nº 15.
                    </p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3758.7104693098117!2d-65.75295568509071!3d-19.59690098679122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x27b12d852a8dcfbc!2zMTnCsDM1JzQ4LjgiUyA2NcKwNDUnMDIuOCJX!5e0!3m2!1ses!2sbo!4v1666498434991!5m2!1ses!2sbo"
                        width="300"
                        height="300"
                        title="direccion2"
                        frameBorder="0"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0" />
                </div>

            </div>

            <h3>Telefono: 68166445</h3>
            <h3>
                Correos: <br />
                <a href='mailto:nidelbarmi.juegos@gmail.com'> nidelbarmi.juegos@gmail.com </a><br />
                <a href='mailto:fundacion.nidel@gmail.com'> fundacion.nidel@gmail.com </a>
            </h3>
        </div>
    )
}
