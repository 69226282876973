import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

//Componentes
import Home from "./Components/Home/Home";
import Navbar from "./Components/Navbar2";
import Conocenos from './Components/Conocenos';
import Educacion from './Components/Educacion';
import Materiales from './Components/Materiales';
import Unete from './Components/Unete';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from './Components/Footer'
import Socialbtn from './Components/Socialbtn'
import { Helmet } from 'react-helmet';

const App = () => {

    return (
        <Router>
            <Navbar />
            <Helmet>
                <title>Fundación Nidel</title>
                <meta name='description' content='Fundación Nidel' />
            </Helmet>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/conocenos" element={<Conocenos />} />
                <Route path="/educacion" element={< Educacion />} />
                <Route path="/materiales" element={< Materiales />} />
                <Route path="/unete" element={<Unete />} />
            </Routes>
            <Socialbtn />
            <Footer />
        </Router>
    );
}

export default App;

