import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Frase1 = () => {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".frase",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "restart none none reverse",
            },
        });
        tl.fromTo(
            ".frase",
            {
                y: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    return (
        <div className="frase1">
            <h2 className="frase"><span className="spanTitle">Apóyanos </span> <br />
                con la <span>compra </span> de juegos, <span>donando</span>, <br />
                <span>compartiendo</span> y <span>siguiéndonos</span> en nuestras <span>redes <br />
                    sociales.</span>
            </h2>
        </div>
    );
};

export default Frase1;
