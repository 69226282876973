import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Hero from "./Hero";
import InicioSaludo from "./InicioSaludo";
import ProyectosBotones from "./ProyectosBotones";
import BanerParalax from "./BanerParalax";
import AyudanosHome from "./AyudanosHome";
import Frase1 from "./Frase1";
import { Helmet } from "react-helmet";

const Home = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="home">
            <Helmet>
                <title>Nidel</title>
                <meta name='description' content='Fundación Nidel' />
                <meta name='keywords' content='Nidel, nidel, niños, fundación, fundacion, Fundacion, Fundación' />
            </Helmet>
            <Hero />
            <InicioSaludo />
            <ProyectosBotones />
            <BanerParalax />
            <Frase1 />
            <AyudanosHome />
        </div>
    );
};

export default Home;
