import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import banner from "../scss/imagenes/VA013.webp";
import intro2 from "../scss/imagenes/intro2.webp";
import titi from "../scss/imagenes/titi.webp";
import titi2 from "../scss/imagenes/titi2.webp";
import illa from "../scss/imagenes/illa.webp";
import illa2 from "../scss/imagenes/illa2.webp";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FaWhatsapp } from "react-icons/fa";

import ReactWhatsapp from "react-whatsapp";
import { Helmet } from "react-helmet";
const whatsappNumber = "+59168166445";
const whatsappMessage = "Hola Nidel...";

const Materiales = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".intro",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "restart none none reverse",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl.fromTo(
            ".intro",
            {
                y: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    useEffect(() => {
        let tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".novedades",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "restart none none reverse",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl2.fromTo(
            ".novedades",
            {
                y: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    useEffect(() => {
        let tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".titi",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "restart none none reverse",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl3.fromTo(
            ".titi",
            {
                x: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                x: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    useEffect(() => {
        let tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".illa",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "restart none none reverse",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl3.fromTo(
            ".illa",
            {
                x: "60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                x: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    useEffect(() => {
        let tl4 = gsap.timeline({
            scrollTrigger: {
                trigger: ".end",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "restart none none reverse",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl4.fromTo(
            ".end",
            {
                y: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    return (
        <div className="fullContent">
            <Helmet>
                <title>Juegos y material pedagógico Nidel</title>
                <meta name='description' content='Juegos y material pedagógico' />
                <meta name='keywords' content='juegos, compras, material, pedagógico' />
            </Helmet>
            <div className="banner">
                <img src={banner} alt="materiales-banner" />
            </div>
            <div className="content">
                <div className="intro">
                    <h2>Juegos y materiales pedagógicos</h2>
                    <p>
                        Tras varios años de trabajar con la metodología del juego obtuvimos experiencia para crear, adaptar y elaborar nuestro propio material.
                        Esta actividad no solo difunde lo que es la Alegría de aprender, sino que además representa un ingreso económico importante para continuar con nuestro servicio.
                    </p>
                </div>

                <div className="segundaIntro">
                    <img src={intro2} alt="Compra de materiales" />
                    <p>
                        Con la compra de cada uno de los juegos o materiales pedagógicos estas colaborando para que continuemos brindando este servicio de apoyo educativo integral a niños y niñas del país.
                    </p>
                </div>

                <div className="novedades">
                    <h2 className="title">Les presentamos las novedades</h2>
                    <p className="parrafo">Juegos colaborativos para niñas y niños, donde todos pueden ganar, invitan a conocer nuestra naturaleza para amarla y así poder cuidarla, mientras se divierten con toda su familia, amigos y amigas.
                    </p>
                    <div className="contentRow titi">
                        <img src={titi} alt="TITI" />
                        <p>
                            Toda la basura del lago Titikaka ha cobrado vida… se ha creado el Monstruo de la contaminación que  quiere terminar con las especies que habitan el lago.
                            TITI es un juego divertido que te ayuda a conocer y amar el lago más alto del mundo, el TITIKAKA.
                            <br /><br />
                            <b>¡Será emocionante… ánimo!</b>
                        </p>
                        <img src={titi2} alt="TITI2" />
                    </div>

                    <div className="contentRow illa">
                        <img src={illa2} alt="ILLA" />
                        <p>
                            Empezamos esta aventura como guardianes de las pequeñas ILLAS (animalitos convertidos en figuras de madera), a quienes guiamos con cortas instrucciones para escapar de los cazadores y así puedan encontrar el camino a su guarida.
                            <br /><br />
                            <b>¡Juntos y juntas lo lograremos!</b>
                        </p>
                        <img src={illa} alt="ILLA2" />
                    </div>
                </div>
                <div className="end">
                    <h2>Contamos con una gran variedad de juegos pedagógicos y juegos colaborativos</h2>
                    <h1><b>Te invitamos</b></h1>
                    <h2>
                        a que puedas consultar por el catálogo <br />o la demostración de algún juego
                    </h2>
                    <ReactWhatsapp
                        number={whatsappNumber}
                        message={whatsappMessage}
                        style={{ backgroundColor: "transparent", border: "none" }}
                    >
                        <FaWhatsapp className="icon" size={70} style={{ fill: 'green' }} />
                    </ReactWhatsapp>
                </div>
            </div>
        </div >
    );
};

export default Materiales;
