import { FaHome, FaHeart, FaChalkboardTeacher } from 'react-icons/fa'
import { BsFillPeopleFill } from 'react-icons/bs'
import logoImg from '../../scss/imagenes/logo.webp'


export const logo = {
    img: logoImg,
    alt: "NIDEL",
    className: "ShowLogo"
}

export const menuItems = [
    {
        item: "inicio",
        ruta: "/",
        icono: <FaHome />,
        id: "inicio",
        defaultMessage: "Inicio",
    },
    {
        item: "NOSOTROS",
        ruta: "/conocenos",
        icono: <BsFillPeopleFill />,
        id: "conocenos",
        defaultMessage: "Conocenos",
    },
    {
        item: "EDUCACION",
        ruta: "/educacion",
        icono: <FaChalkboardTeacher />,
        id: "educacion",
        defaultMessage: "Educacion",
    },
    {
        item: "MATERIALES",
        ruta: "/materiales",
        icono: <FaChalkboardTeacher />,
        id: "materiales",
        defaultMessage: "Materiales",
    },
    {
        item: "UNETE",
        ruta: "/unete",
        icono: <FaHeart />,
        id: "unete",
        defaultMessage: "Únete y dona",
    },
]