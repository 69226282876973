import { useRef, useEffect } from "react";

import imagenw from "../../scss/imagenes/1.webp";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const InicioSaludo = () => {
    gsap.registerPlugin(ScrollTrigger);
    let title = useRef(null);
    let p1 = useRef(null);
    let h2 = useRef(null);
    let img1 = useRef(null);

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".saludo",
                start: "top 80%", //opcional
                end: "bottom 500px",
                toggleActions: "restart none none reverse",
            },
        });
        tl.fromTo(
            title,
            {
                y: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
        tl.fromTo(
            p1,
            {
                x: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                x: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
        tl.fromTo(
            h2,
            {
                x: "60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                x: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );

        tl.fromTo(
            ".backgroundimageSaludo",
            {
                opacity: 0,
            },
            {
                opacity: 0.7,
                duration: 2,
            },
            0.2
        );

        tl.fromTo(
            img1,
            {
                y: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    return (
        <div className="saludo">
            <div className="backgroundimageSaludo"></div>
            <div className="contenedorDoble">

                <div className="textos">
                    <h1 className="titleDoble" ref={(element) => (title = element)}>
                        Te damos la bienvenida <br /> <span>al sueño NIDEL</span>
                    </h1>

                    <p ref={(element) => (p1 = element)}>
                        Somos Nidel, fundación que promueve una educación integral a través de crear espacios y materiales lúdicos, para fortalecer el desarrollo pleno del potencial de niños y niñas, promoviendo la autogestión de su aprendizaje, para ser miembros de una comunidad que cuida su propio ser, su comunidad y el planeta.
                    </p>
                    <h3 className="endText" ref={(element) => (h2 = element)}>
                        ¡Te invitamos a conocernos <br /> y ser parte de este sueño!
                    </h3>
                </div>
                <img src={imagenw} className="imagen" ref={(element) => (img1 = element)} alt="conocenos" />
            </div>

        </div >
    );
};

export default InicioSaludo;
