import React, { useState } from "react";

import emailjs from "@emailjs/browser";

import { Comment } from "react-loader-spinner";

const SERVICE_ID = "test_0010101";
const TEMPLATE_ID = "template_519ly89";
const PUBLIC_ID = "IvnqvGz5KZOdTdsea";

const Contact = () => {
    const initialData = {
        from_name: "",
        form_correo: "",
        asunto: "",
        message: "",
        telefono: "",
    };

    const [mensaje, setMensaje] = useState("-");
    const [datos, setDatos] = useState(initialData);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name]: event.target.value,
        });
    };

    const enviarDatos = (event) => {
        event.preventDefault();
        setLoading(true);
        emailjs.send(SERVICE_ID, TEMPLATE_ID, datos, PUBLIC_ID).then(
            (response) => {
                setMensaje("Mensaje enviado Correctamente");
                setLoading(false);
                setDatos(initialData);
                console.log("SUCCESS!", response.status, response.text);
            },
            (err) => {
                setLoading(false);
                setMensaje(
                    "Tuvimos un problema con tu mensaje. Intentalo de nuevo o envia un mensaje al Whatsapp"
                );
                console.log("FAILED...", err);
            }
        );
    };

    return (
        <div className="contacto">
            <h2>Envianos un mensaje</h2>
            <div style={{ margin: "10px" }}>
                {mensaje.length && <h2>{mensaje}</h2>}
            </div>

            {loading ? (
                <Comment
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="comment-loading"
                    wrapperStyle={{}}
                    wrapperClass="comment-wrapper"
                    color="#fff"
                    backgroundColor="#F4442E"
                />
            ) : (
                <form className="row" onSubmit={enviarDatos}>
                    <div className="data">
                        <label htmlFor="nombre">Nombre:</label>
                        <input
                            type="text"
                            placeholder="Nombre"
                            className="form-control"
                            onChange={handleInputChange}
                            value={datos.from_name}
                            name="from_name"
                        ></input>
                    </div>
                    <div className="data">
                        <label htmlFor="correo">Correo:</label>
                        <input
                            type="email"
                            placeholder="Correo"
                            className="form-control"
                            onChange={handleInputChange}
                            value={datos.form_correo}
                            name="form_correo"
                        ></input>
                    </div>
                    <div className="data">
                        <label htmlFor="telefono">Telefono:</label>
                        <input
                            type="tel"
                            placeholder="Telefono"
                            className="form-control"
                            onChange={handleInputChange}
                            value={datos.telefono}
                            name="telefono"
                        ></input>
                    </div>
                    <div className="data">
                        <label htmlFor="asunto">Asunto:</label>
                        <input
                            type="text"
                            placeholder="Asunto"
                            className="form-control"
                            onChange={handleInputChange}
                            value={datos.asunto}
                            name="asunto"
                        ></input>
                    </div>
                    <div className="data">
                        <label htmlFor="comentario">Comentario:</label>
                        <textarea
                            rows="5"
                            placeholder="Comentario"
                            className="form-control"
                            onChange={handleInputChange}
                            value={datos.message}
                            name="message"
                        ></textarea>
                    </div>
                    <div className="data">
                        <button type="submit" className="btn btn-primary">
                            Enviar
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default Contact;
