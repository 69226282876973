import {
    FaFacebook,
    FaInstagram,
    FaWhatsapp,
    FaMailBulk,
} from "react-icons/fa";

import ReactWhatsapp from "react-whatsapp";

const whatsappNumber = "+59168166445";
const whatsappMessage = "Hola Nidel...";

const facebookLink = "https://fb.me/fundacionNidel";
const instagramLink = "https://instagram.com/fundacion.nidel";
const mailLink = "mailto:fundacion.nidel@gmail.com";

const index = () => {
    return (
        <div className="socialMedia">
            <div>
                <a href={facebookLink} target="_blank" rel="noreferrer noopener">
                    <FaFacebook className="icon" />
                </a>
            </div>
            <div>
                <a href={instagramLink} target="_blank" rel="noreferrer noopener">
                    <FaInstagram className="icon" />
                </a>
            </div>
            <div>
                <ReactWhatsapp
                    number={whatsappNumber}
                    message={whatsappMessage}
                    style={{ backgroundColor: "transparent", border: "none" }}
                >
                    <FaWhatsapp className="icon" />
                </ReactWhatsapp>
            </div>
            <div>
                <a href={mailLink} target="_blank" rel="noreferrer noopener">
                    <FaMailBulk className="icon" />
                </a>
            </div>
        </div>
    );
};

export default index;
