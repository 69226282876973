import React from "react";
import membretado from "../../scss/imagenes/Sello-Insignia_-09.webp";

import ReactWhatsapp from "react-whatsapp";

import {
    FaFacebook,
    FaInstagram,
    FaWhatsapp,
    FaLocationArrow,
    FaPhone,
    FaMailBulk,
} from "react-icons/fa";

const direccionNidelAlto =
    "Zona Alto Lima Segunda Sección, Calle Sorata N°2000, entre Av. Panamá y Av. Ecuador";
const direccionNidelPotosi = "Zona San Pedro, Calle Rojas de Luna Nº 15";
const correo = "fundacion.nidel@gmail.com";
const whatsappNumber = "+59168166445";
const phone = "68166445";
const whatsappMessage = "Hola Nidel...";
const facebookLink = "https://fb.me/fundacionNidel";
const instagramLink = "https://instagram.com/fundacion.nidel";
const mailLink = "mailto:fundacion.nidel@gmail.com";

const index = () => {
    return (
        <div className="footer">
            <div className="left">
                <div className="title">
                    <h4>Siguenos en nuestras redes o contactanos:</h4>
                </div>
                <div className="icons">
                    <div className="row">
                        <a href={facebookLink} target="_blank" rel="noreferrer noopener">
                            <FaFacebook className="icon" />
                        </a>
                        <p>Fundación NIDEL </p>
                    </div>
                    <div className="row">
                        <a href={instagramLink} target="_blank" rel="noreferrer noopener">
                            <FaInstagram className="icon" />
                        </a>
                        <p>fundacion.nidel</p>
                    </div>
                    <div className="row">
                        <ReactWhatsapp
                            number={whatsappNumber}
                            message={whatsappMessage}
                            style={{ backgroundColor: "transparent", border: "none" }}
                        >
                            <FaWhatsapp className="icon" />
                        </ReactWhatsapp>
                        <p>68166445</p>
                    </div>
                    <div className="row">
                        <a href={mailLink} target="_blank" rel="noreferrer noopener">
                            <FaMailBulk className="icon" />
                        </a>
                        <p>fundacion.nidel@gmail.com</p>
                    </div>
                </div>
            </div>

            <div className="center">
                <h2>Fundación NIDEL</h2>
                <ul>
                    <li>
                        <FaLocationArrow />
                        <span className="textCenter"><span>El Alto: </span>
                            {direccionNidelAlto}</span>
                    </li>
                    <li>
                        <FaLocationArrow />
                        <span className="textCenter"><span>Potosi: </span>{direccionNidelPotosi}</span>
                    </li>
                    <li>
                        <FaPhone />
                        <span className="textCenter"><span>Telefono: </span>{phone}</span>
                    </li>
                    <li>
                        <FaMailBulk />
                        <span className="textCenter"><span>Correo: </span>{correo}</span>
                    </li>
                </ul>

                <h5>La Paz - Bolivia 2022</h5>
            </div>
            <div className="right sello">
                <img src={membretado} alt="logo2" className="membreteLogo" />
            </div>
        </div>
    );
};

export default index;
