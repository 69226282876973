import { useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";

import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons";

import { NavLink as Link } from "react-router-dom";
import { logo, menuItems } from "./NavbarItems";

import { gsap } from "gsap";

const Navbar2 = () => {

    const [showMobile, setShowMobile] = useState(false);

    const [navBar, setNavBar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavBar(true);
        } else {
            setNavBar(false);
        }
    };

    window.addEventListener("scroll", changeBackground);

    useEffect(() => {
        return () => {
            let tl = gsap.timeline();
            tl.fromTo(
                ".menu",
                {
                    x: "1000",
                    opacity: "0",
                },
                {
                    x: "0",
                    opacity: "1",
                    duration: "1",
                },
                0.5
            );
            tl.fromTo(
                ".logoContainer",
                {
                    y: "-100",
                },
                {
                    y: "0",
                    opacity: "1",
                    duration: "1",
                },
                0.5
            );
        };
    }, []);

    return (
        <div className="container">
            <div className={navBar ? " wrapper fullwrapper" : "wrapper"}>
                <div className="background">
                    <IconContext.Provider value={{ style: { fontSize: "2em" } }}>
                        <div className="logoContainer">
                            <Link to="/">
                                <img src={logo.img} alt={logo.alt} className={logo.className} />
                            </Link>
                        </div>

                        <div
                            className="mobileIcon"
                            onClick={() => setShowMobile(!showMobile)}
                        >
                            {showMobile ? <FaTimes /> : <FaBars />}
                        </div>

                        {/* <ul className={`menu ${showMobile}`} open={showMobile}> */}
                        <ul className={`menu ${showMobile ? "showMobile" : ""}`}>
                            {menuItems.map((item, key) => (
                                <li key={key} className="menuItem">
                                    <Link className="menuItemLink" to={item.ruta} onClick={() => setShowMobile(!showMobile)}>
                                        <div>
                                            {item.icono}
                                            <FormattedMessage
                                                id={item.id}
                                                defaultMessage={item.defaultMessage}
                                            />
                                        </div>
                                    </Link>
                                </li>
                            ))}

                            {/* <li className="worldIcon">
                <BiWorld onClick={() => setShowLanguaje(!showLanguaje)} />
              </li>

              <li className={`menuItem leng ${showLanguaje && "menuLeng"}`}>
                <button
                  className="lang es"
                  onClick={() => state.establecerLenguaje("es-BO")}
                />
                <button
                  className="lang en"
                  onClick={() => state.establecerLenguaje("en-US")}
                />
                <button
                  className="lang fr"
                  onClick={() => state.establecerLenguaje("fr-FR")}
                />
              </li> */}
                        </ul>
                    </IconContext.Provider>
                </div>
            </div>
        </div >
    );
};

export default Navbar2;
