export const imagesBanner = [
    {
        image: require('../../scss/imagenes/banner1.webp'),
        alt: 'banner1'
    },
    {
        image: require('../../scss/imagenes/banner2.webp'),
        alt: 'banner2'
    },
    {
        image: require('../../scss/imagenes/banner3.webp'),
        alt: 'banner3'
    }
]