import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import banner from "../scss/imagenes/conocenos.webp";
import mision from "../scss/imagenes/VA051-mid.webp";
import vision from "../scss/imagenes/vision.webp";
import brujula from "../scss/imagenes/brujula1.webp";
import fundador from "../scss/imagenes/fundador.webp";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Helmet } from "react-helmet";

const Conocenos = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".intro",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "restart none none reverse",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl.fromTo(
            ".intro",
            {
                y: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    useEffect(() => {
        let tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: ".mision",
                start: "top 80%", //opcional
                end: "bottom 500px",
                // markers: true,
                toggleActions: "restart complete none reverse",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl2.fromTo(
            ".mision",
            {
                x: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                x: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    useEffect(() => {
        let tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: ".vision",
                start: "top 80%", //opcional
                end: "bottom 500px",
                toggleActions: "restart complete none reverse",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl3.fromTo(
            ".vision",
            {
                x: "60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                x: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    useEffect(() => {
        let tl4 = gsap.timeline({
            scrollTrigger: {
                trigger: ".principios",
                start: "top 80%", //opcional
                end: "bottom 500px",
                toggleActions: "restart complete none complete",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl4.fromTo(
            ".lastConocenos",
            {
                y: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                y: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    useEffect(() => {
        let tl4 = gsap.timeline({
            scrollTrigger: {
                trigger: ".fundador",
                start: "top 80%", //opcional
                end: "bottom 500px",
                toggleActions: "restart complete none complete",
                // toggleActions: 'restart complete none reverse',
            },
        });
        tl4.fromTo(
            ".fundador",
            {
                x: "-60",
                opacity: 0,
                ease: "ease-in",
            },
            {
                x: "0",
                opacity: 1,
                ease: "Expo.easeOut",
                duration: 2,
            },
            0.2
        );
    }, []);

    return (
        <div className="fullContent">
            <Helmet>
                <title>Conocenos Nidel</title>
                <meta name='description' content='Conocenos Nidel' />
                <meta name='keywords' content='conocenos, nidel, niños, fundación, fundacion' />
            </Helmet>
            <div className="banner">
                <img src={banner} alt="conocenos-banner" />
            </div>

            <div className="content">
                <div className="intro">
                    <h2>Somos la fundacion Nidel</h2>
                    <p>
                        Estamos comprometidos con los niños y niñas del país para aportar a la alegria de aprender.
                    </p>
                </div>

                <div className="fundador">
                    <div className="img">
                        <img src={fundador} alt="Padre Juan" />
                        <p>1928-2017</p>
                    </div>
                    <div className="parrafo">
                        <h3> Rememoremos </h3>
                        <p>
                            La Fundación NIDEL, nace como iniciativa del padre Juan Claesen Dewit, de nacionalidad
                            belga que se dedicó al servicio y a la religión, fue en su retiro que vino a Bolivia con la
                            intención de continuar su vida de servicio. A este proyecto se sumó la pareja de esposos
                            franco-boliviano, Valery Díaz y Nora Omonte.
                            <br />
                            <br />
                            Es así que el 20 de octubre de 1993 empieza esta gran misión de apoyo educativo a
                            Niñas, niños y adolescentes de Barrios Mineros, razón del nombre de la Fundación
                            NIDELBARMI.
                            <br />
                            <br />
                            Gracias al padre Juan y a mucha gente, NIDEL continua dando un servicio de amor.
                        </p>

                    </div>

                </div>
                <div className="mision">
                    <div className="contentInfo">
                        <h3>Nuestra Misión</h3>
                        <p>
                            Somos una Fundación que ofrece a niños, niñas y adolescentes un
                            servicio educativo integral, utilizando metodologías lúdicas para
                            fortalecer el desarrollo pleno de su potencial y motivar la
                            auto-gestión de su aprendizaje, a través de la orientación
                            familiar y de la permanente actualización e innovación de
                            educadores y educadoras.
                        </p>
                    </div>
                    <div className="imgContent">
                        <img src={mision} alt="mision" />
                    </div>
                </div>
                <div className="vision">
                    <div className="contentInfo">
                        <h3>Nuestra Visión</h3>
                        <p>
                            Ser un movimiento de auto-gestión del aprendizaje significativo
                            que, a través de la alegría del juego, impulsa a niños, niñas y
                            adolescentes a ser los protagonistas de su propia educación para
                            ser agentes de cambio y transformar la vida de sus familias y su
                            entorno.
                        </p>
                    </div>
                    <div className="imgContent">
                        <img src={vision} alt="vision" />
                    </div>
                </div>

                <div className="lastConocenos">
                    <div className="principios">
                        <h3>Principios de Nidel</h3>

                        <ul>
                            <li>
                                Creemos que todo ser humano tiene un gran potencial de
                                aprendizaje.
                            </li>
                            <li>Aprendemos a crecer, jugando y con alegría.</li>
                            <li>
                                El amor se revela a través del respeto, la inclusión y compartir
                                lo mejor.
                            </li>
                            <li>Lo que se enseña se aprende dos veces.</li>
                            <li>
                                Resolvemos los conflictos a través del diálogo, la reflexión y
                                la no violencia.
                            </li>
                            <li>Jugar es asunto serio.</li>
                        </ul>
                    </div>
                    <div className="imgBrujula">
                        <h2>Brujula de los valores Nidel</h2>
                        <img src={brujula} alt="brujula-nidel" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Conocenos;
